export default class GenericField {
    constructor(name, label, filterParams) {
        this.name = name;
        this.label = label;
        // for filter
        const { primary = true, placeholder = '', filter_label } = filterParams;
        this.key = name;
        this.primary = primary;
        this.placeholder = placeholder;
        this.filter_label = filter_label;
    }

    forPresenter() {
        throw new Error('Called superclass');
    }

    forFilterCast() {
        throw new Error('Called superclass');
    }

    forFilterRules() {
        throw new Error('Called superclass');
    }

    forFormCast() {
        throw new Error('Called superclass');
    }

    forFormRules() {
        throw new Error('Called superclass');
    }

    forFilterInitialValue() {
        throw new Error('Called superclass');
    }

    forFormInitialValue() {
        throw new Error('Called superclass');
    }

    extendField(options) {
        const { bold, labelType, linkable, booleanType, customField, portalTarget, sortable} = options;
        if (bold) {
            this.bold = bold;
        }
        if (labelType) {
            this.labelType = labelType;
        }
        if (linkable) {
            this.linkable = linkable;
        }
        if (booleanType) {
            this.booleanType = booleanType;
        }
        if (customField) {
            this.customField = customField;
        }
        if (portalTarget) {
            this.portalTarget = portalTarget;
        }
        if (sortable) {
            this.sortable = sortable;
        }

        return this;
    }
}
